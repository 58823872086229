import { QueryKey, useQueryClient } from '@tanstack/react-query'

import { mergeParams } from '@procsea/common/utils'

import { useUserSellerCompanyId } from 'src/contexts/userContext'
import { useSegment } from 'src/hooks'
import {
  useDeleteEntityMutation,
  usePatchEntityMutation,
  usePostEntityMutation,
} from 'src/queries/entity'
import {
  PaginatedData,
  UseDeleteEntityMutationProps,
  UseEntityMutationProps,
} from 'src/queries/entity.types'
import {
  GenerateQueriesArgs,
  UsePaginatedEntitiesQueryArgs,
  UseSingleEntityQueryArgs,
  generateQueries,
} from 'src/queries/queryGenerator'
import {
  SellerProductInstance,
  SellerProductInstanceQueryParams,
  SellerProductInstanceRequestBody,
} from 'src/types'
import { ContentType } from 'src/utils'

import { SPIS_WITH_STOCKS_QUERY_KEY } from './listWithStocks/listWithStocks'
import { SPI_WITH_STOCKS_QUERY_KEY } from './retrieveWithStocks/retrieveWithStocks'

export const SELLER_PRODUCT_INSTANCES_ENDPOINT = 'seller_product_instances'
export const SELLER_PRODUCT_INSTANCES_QUERY_KEY = 'sellerProductInstances'

const generateQueriesConfig: GenerateQueriesArgs<
  SellerProductInstance,
  {},
  SellerProductInstanceRequestBody
> = {
  endpoint: SELLER_PRODUCT_INSTANCES_ENDPOINT,
  queryKey: SELLER_PRODUCT_INSTANCES_QUERY_KEY,
}

export const {
  usePaginatedEntitiesQuery,
  usePostEntity: usePostSellerProductInstance,
  usePutEntity: usePutSellerProductInstance,
  useSingleEntityQuery,
} = generateQueries(generateQueriesConfig)

type UseSellerProductInstanceQueryArgs = UseSingleEntityQueryArgs<
  SellerProductInstance,
  Partial<SellerProductInstanceQueryParams>
>

export const useSellerProductInstanceQuery = (args: UseSellerProductInstanceQueryArgs) => {
  const sellerCompanyId = useUserSellerCompanyId()

  const defaultsParams: UseSellerProductInstanceQueryArgs = {
    queryOptions: { enabled: !!sellerCompanyId },
    queryParams: { sellerCompanyId: sellerCompanyId },
  }

  return mergeParams(useSingleEntityQuery)(defaultsParams)(args)
}

type UsePaginatedSellerProductInstancesQueryArgs<TData> = UsePaginatedEntitiesQueryArgs<
  SellerProductInstance,
  Partial<SellerProductInstanceQueryParams>,
  TData
>

export const usePaginatedSellerProductInstancesQuery = <TData = SellerProductInstance>(
  args: UsePaginatedSellerProductInstancesQueryArgs<TData> = {}
) => {
  const sellerCompanyId = useUserSellerCompanyId()

  const defaultsParams: UsePaginatedSellerProductInstancesQueryArgs<TData> = {
    queryOptions: { enabled: !!sellerCompanyId },
    queryParams: { sellerCompanyId: sellerCompanyId },
  }

  return mergeParams(usePaginatedEntitiesQuery)(defaultsParams)(args)
}

interface UsePatchOrderMutationArgs {
  id: Id
  queryOptions?: UseEntityMutationProps<Partial<SellerProductInstance>>['queryOptions']
}

export const usePatchSellerProductInstanceMutation = ({
  id,
  queryOptions,
}: UsePatchOrderMutationArgs) =>
  usePatchEntityMutation<Partial<SellerProductInstance>>({
    endpoint: SELLER_PRODUCT_INSTANCES_ENDPOINT,
    entityId: id,
    queryOptions,
  })

type DeleteQueryContext = {
  previousSpis?: PaginatedData<SellerProductInstance>
  queryKey?: QueryKey
}

interface UseDeleteOrderMutationArgs {
  queryOptions?: UseDeleteEntityMutationProps<Id, DeleteQueryContext>['queryOptions']
}

export const useDeleteSellerProductInstanceMutation = ({
  queryOptions,
}: UseDeleteOrderMutationArgs = {}) =>
  useDeleteEntityMutation<Id, DeleteQueryContext>({
    endpoint: SELLER_PRODUCT_INSTANCES_ENDPOINT,
    queryOptions,
  })

export interface PostSellerProductInstancesCsvQueryParams {
  importedFile: File
}

export interface PostSellerProductInstancesCsvResponse {
  totalSuccess: number
  totalIgnored: number
  totalError: number
  csvResult: string
}

export const usePostSellerProductInstancesCsvMutation = () => {
  const { track } = useSegment()
  const queryClient = useQueryClient()

  return usePostEntityMutation<
    PostSellerProductInstancesCsvQueryParams,
    PostSellerProductInstancesCsvResponse
  >({
    endpoint: 'seller_product_instances_import',
    queryOptions: {
      onMutate: request => {
        track('Seller - Price Sheet Importation Started', {
          fileExtension: 'csv',
          fileName: request.importedFile.name,
        })

        return undefined
      },
      onSuccess: (response, request) => {
        if (response.totalSuccess > 0) {
          queryClient.invalidateQueries({ queryKey: [SELLER_PRODUCT_INSTANCES_QUERY_KEY] })
          queryClient.invalidateQueries({ queryKey: [SPIS_WITH_STOCKS_QUERY_KEY] })
          queryClient.invalidateQueries({ queryKey: [SPI_WITH_STOCKS_QUERY_KEY] })
        }

        track('Seller - Price Sheet Importation Succeed', {
          fileExtension: 'csv',
          fileName: request.importedFile.name,
          offersCount: response.totalSuccess,
          offersErrors: response.totalError,
          offersIgnored: response.totalIgnored,
        })
      },
    },
    contentType: ContentType.MULTIPART_FORM_DATA,
  })
}
